import React, {useState, useMemo} from 'react';
import Block from '../../Widgets/Block';
import Row from '../../Widgets/Row';
import * as Ant from 'antd';
import {
  getDisplayState,
  getCalculation,
  isOrderDisabled,
  getOrderId,
  getPaymentProvider,
} from '../../Utils/OrderUtil';
import {
  PAYMENT_TYPE,
  PAYMENT_STATUS,
  PAYMENT_SUB_TYPE,
  ORDER_STATE,
  ORDER_TYPE,
  ORDER_STATUS_COLORS,
} from '../../dictionary';
import IconButton from '../../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import Alert from '../../Widgets/Alert';
import {UISTATE as ORDER_DIALOG_TYPE} from '../../Dialogs/OrderDialog';
import * as DateUtil from '../../Utils/DateUtil';
const appConfig = require('../../data.json');

export default function OrderBlock(props) {
  const {order, setOpenOrderDialog} = props;
  const {cart, disabled, calculations} = useMemo(() => {
    let cart = {};
    if (order.cart) {
      try {
        cart = JSON.parse(order.cart);
      } catch (err) {}
    }

    return {
      cart,
      disabled: isOrderDisabled(order),
      calculations: getCalculation(cart.calculations),
    };
  }, [order]);

  return (
    <Block>
      <h2> 訂單資訊</h2>
      <div className="divider" />
      <div>
        <Alert
          style={{marginBottom: 20}}
          color={ORDER_STATUS_COLORS[order.display_state]}
          backgroundColor={
            ['completed', 'voided'].includes(order.display_state)
              ? 'white'
              : false
          }>
          訂單狀態：{ORDER_STATE[order.display_state]}
          {order.void_reason && `，取消原因：${order.void_reason}`}
        </Alert>
        <Ant.Row>
          <Ant.Col span={12}>
            <div className="row">
              <h4>訂單編號</h4>
              <div>{getOrderId(order)}</div>
            </div>
            <div className="row">
              <h4>{getPaymentProvider(appConfig.paymentProvider)}單號</h4>
              <div>{order.payment_transaction_id}</div>
            </div>
            <div className="row">
              <h4>訂單類型</h4>
              <div> {ORDER_TYPE[order.order_type]}</div>
            </div>
            <div className="row">
              <h4>建立時間</h4>
              <div>
                {order.created.split('T')[0]} {order.created.slice(11, 16)}
              </div>
            </div>
            <div className="row">
              <h4>更新時間</h4>
              <div>
                {order.updated.slice(0, 10)} {order.updated.slice(11, 16)}
              </div>
            </div>
            <div className="row">
              <h4>稿件上傳時間</h4>
              <div>
                {order.last_upload_time?.slice(0, 10)}{' '}
                {order.last_upload_time?.slice(11, 16)}
              </div>
            </div>
            <div className="row">
              <h4>使用折扣碼</h4>
              <div>{order.coupon || '-'}</div>
            </div>
            <div className="row">
              <h4>是否急件</h4>
              <div>{order.is_urgent ? '是' : '否'}</div>
            </div>
            <div className="row">
              <h4>
                急件時間
                <IconButton
                  onClick={() => setOpenOrderDialog(ORDER_DIALOG_TYPE.NOTE)}
                  disabled={disabled}
                  hoverColor={appConfig.colors.sub}>
                  <Edit />
                </IconButton>
              </h4>
              <div>
                {order.urgent_time?.slice(0, 10)}{' '}
                {order.urgent_time?.slice(11, 16)}
              </div>
            </div>

            {order &&
              order.payment_status !== 'success' &&
              order.payment_status !== 'failure' &&
              order.code_no && (
                <React.Fragment>
                  <div className="row">
                    <h4>
                      {
                        {
                          atm: '轉帳帳號',
                          cvs: '超商繳費代碼',
                        }[order.payment_subtype]
                      }
                    </h4>
                    {order.bank_code &&
                      ' 銀行代碼 ' + order.bank_code + ' - 帳號 '}
                    <div> {order.code_no}</div>
                  </div>
                  <div className="row">
                    <h4>繳費期限</h4>
                    <div>
                      {' '}
                      {order.pay_deadline
                        ? DateUtil.dateParser(order.pay_deadline)
                        : '-'}
                    </div>
                  </div>
                </React.Fragment>
              )}
            {order && order.payment_type === 'offline' && (
              <React.Fragment>
                <div className="row">
                  <h4>匯款戶名</h4>
                  <div> {order.remittance_account}</div>
                </div>
                <div className="row">
                  <h4>匯款後五碼</h4>
                  <div> {order.remittance_number}</div>
                </div>
                <div className="row">
                  <h4>匯款日期</h4>
                  <div>{DateUtil.dateParser(order.remitted_time)}</div>
                </div>
              </React.Fragment>
            )}
          </Ant.Col>
          <Ant.Col span={12}>
            <div className="row">
              <h4>付款方式</h4>
              <div> {PAYMENT_TYPE[order.payment_type]}</div>
            </div>
            <div className="row">
              <h4>付款細項</h4>
              <div>{PAYMENT_SUB_TYPE[order.payment_subtype]}</div>
            </div>
            <div className="row">
              <h4>下載估價單</h4>
              <div>{order.downloaded ? '已下載' : '未下載'}</div>
            </div>
            <div className="row">
              <h4>自動開立發票</h4>
              <div>{order.ignore_invoice ? '否' : '是'}</div>
            </div>
            {order.payment_type === 'monthly' && (
              <div className="row">
                <h4>月結對帳單</h4>
                <div>
                  {order.monthly_order
                    ? '已結算 #' + order.monthly_order
                    : '未結算'}
                </div>
              </div>
            )}
            <div className="row">
              <h4>訂單備註</h4>
              <div>{(order.note && order.note) || '無'}</div>
            </div>
            <div className="row">
              <h4>
                員工備註
                <IconButton
                  onClick={() => setOpenOrderDialog(ORDER_DIALOG_TYPE.NOTE)}
                  disabled={disabled}
                  hoverColor={appConfig.colors.sub}>
                  <Edit />
                </IconButton>
              </h4>
              <div>{order.staff_note || '無'}</div>
            </div>
            <div className="row">
              <h4>授權分享</h4>
              <div>{order.is_share_order_photo ? '是' : '否'}</div>
            </div>
            <div className="row">
              <h4>授權備註</h4>
              <div>{order.share_info || '無'}</div>
            </div>
          </Ant.Col>
        </Ant.Row>
      </div>
    </Block>
  );
}
