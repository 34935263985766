import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import {parseProductConfig} from './PdfCartItemTable';
const appConfig = require('../data.json');

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    src: '/MiSans-Normal.ttf',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
  form: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  bgColorRow: {
    backgroundColor: '#f3f3f3',
    textAlign: 'center',
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cell: {
    flex: 1,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBig: {
    flex: 2,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  cellBigger: {
    flex: 5.29,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 12,
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
  },
  image: {
    width: 200,
  },
});

// Create Document Component
const QuotationDocument = (props) => {
  let {order, orderItems, productSpec} = props;
  let {information} = appConfig;
  let cart = JSON.parse(order.cart);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={[
            styles.title,
            {whiteSpace: 'wrap', lineHeight: 1.2, marginBottom: 10},
          ]}>
          {`${information.fullname}`}-估價單
        </Text>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>訂單資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.display_id}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              建立時間:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {moment(order.created).format('YYYY-MM-DD HH:MM')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              客戶名稱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.name}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              行動電話:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.phone}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              公司抬頭:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.company_title}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              電子信箱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.email}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              統一編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.gui_number}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              地址:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {cart.config.city} {cart.config.district}
              {cart.config.address}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <View style={[styles.row, {padding: 0, backgroundColor: '#f3f3f3'}]}>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              品項
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              商品名稱
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              規格
            </Text>
            <Text style={styles.cell}>金額小計(含稅)</Text>
          </View>

          {orderItems.map((orderItem, idx) => (
            <View style={styles.row}>
              <Text
                style={[
                  styles.cell,
                  {borderTop: 0, borderRight: 0, textAlign: 'center'},
                ]}>
                {idx + 1}
              </Text>
              <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
                {order.custom_note || cart.items[orderItem.item_index].name}
              </Text>
              <View
                style={[
                  styles.cellBig,
                  {borderTop: 0, borderRight: 0, flexDirection: 'column'},
                ]}>
                {(() => {
                  const parsedConfigs = parseProductConfig({
                    itemInCart: cart.items[orderItem.item_index],
                    productSpec,
                    order,
                  });
                  return parsedConfigs.map((config, idx) => (
                    <Text key={idx} style={{paddingVertical: 0}}>
                      {config}
                    </Text>
                  ));
                })()}
                {order.order_type === 'custom' ? (
                  <Text style={{fontSize: 10}}>
                    自定規格：{cart.items[orderItem.item_index].custom_note}
                  </Text>
                ) : null}
              </View>
              <Text style={[styles.cell, {borderTop: 0}]}>
                ${cart.items[orderItem.item_index].price}
              </Text>
            </View>
          ))}

          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              商品小計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.items_amount + cart.calculations.items_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              運費(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.fee + cart.calculations.fee_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              紅利(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.bonus + cart.calculations.bonus_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              折扣(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.discount + cart.calculations.discount_tax}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              總計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.amount}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>估價單備註</Text>
          <Text
            style={[
              styles.cell,
              {
                borderTop: 0,
                borderBottom: 0,
                paddingTop: 4,
                paddingBottom: 18,
                color: 'red',
                fontSize: 9,
                lineHeight: 1.5,
                whiteSpace: 'wrap',
              },
            ]}>
            {`1. 本估價單有效期間為 7天，7天後系統訂單將自動作廢且不保留原訂單優惠，請留意報價及回簽時間。\n2. 本估價單如貴公司簽署製作視同訂購契約，已並同意接受本網站客服中心所有服務條款。\n中途如有更改或因故停止製作，本公司則不退回該訂單款項。\n3. 校稿與訂單皆採用系統回覆紀錄， Mail、Line對話訊息、傳真等文字亦為執行憑據。\n4. 客戶提供之文字圖片等相關製作資料，保證 絕無他人著作權，版權或涉及任何法律問題，如有涉及概由委製公司負責。\n5. 客戶提供之檔案或稿件，經確認打樣及確認圖稿後所 產生之檔案問題，本公司概不負責。請客 戶務必校對確認
印製檔案之內容文字圖片及完稿製作。\n6. 本報價單如蒙同意，則轉為買賣契約，金額無誤後煩請蓋章 (簽)回傳，本報價單經雙方確認簽章後即可視為\n正式訂單且具有法律效力。\n7. 為維護您的權益，下單前請務必詳讀本網站所有『條款聲明』及『印刷須知』， 與本網站平台交易之始，\n即表示您同意上述須知事項。\n8. 本網站平台擁有審核、終止承攬與解釋條約之權利。`}
          </Text>
          <Text style={styles.bgColorRow}>訂購簽認資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              客戶代表:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.company_title}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              公司抬頭:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {information.fullname}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              統一編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.invoiceConfig.gui_number}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              統一編號:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {information.gui_number}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              聯絡人:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.name}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              聯絡電話:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {information.tel}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              聯絡電話:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {cart.config.tel}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              電子信箱:
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {information.email}
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              style={[
                styles.cell,
                {borderTop: 0, borderRight: 0, lineHeight: 1.5, height: 160},
              ]}>{`客戶簽認\n(大小章):`}</Text>
            <View
              style={[
                styles.cell,
                {borderTop: 0, lineHeight: 1.5, height: 160},
              ]}>
              <Text style={[]}>{`${information.name}簽認\n(大小章):`}</Text>
              <Image
                src={`/images/${appConfig.information.invoice_seal_images}`}
                style={styles.image}
              />
            </View>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂購簽認日期:
            </Text>
            <Text style={[styles.cellBigger, {borderTop: 0}]}></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const QuotationFormPdfDownload = (props) => {
  const cart = JSON.parse(props.order.cart);
  return (
    <PDFDownloadLink
      style={props.style}
      document={<QuotationDocument {...props} />}
      fileName={`${props.order.id}-${decodeURI(cart.config.name)}-估價單.pdf`}>
      {({blob, url, loading, error}) => (loading ? '下載中...' : '匯出估價單')}
    </PDFDownloadLink>
  );
};

export {QuotationFormPdfDownload, QuotationDocument};
