import React, {useContext, useCallback, useEffect, useState} from 'react';
import {errorHandler} from '../errors';
import {Spin, Input, Select} from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {useOutlet} from 'reconnect.js';

export default function AddressField(props) {
  const {
    onChange,
    zip_code = null,
    city = null,
    district = null,
    address = '',
  } = props;

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [actions] = useOutlet('actions');

  const getZipCode = useCallback(async (zip_code) => {
    setLoading(true);
    try {
      let resp = await actions.getZipCode(zip_code);
      if (resp) {
        onChange({
          zip_code,
          city: resp.countyName,
          district: resp.townName,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await actions.getCities();
        setCities(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    })();
  }, []);

  const getDistricts = useCallback(async () => {
    if (city) {
      setLoading(true);
      try {
        let resp = await actions.getDistricts(city);
        setDistricts(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [props.city]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: 300,
      }}>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{fontSize: 16}} />}
        style={{alignSelf: 'center', marginRight: 10, marginBottom: 10}}
      />
      <Input
        placeholder="郵遞區號"
        disabled={loading}
        value={zip_code}
        onChange={(e) => {
          let value = e.target.value;
          onChange({zip_code: value});
        }}
        onBlur={() => {
          if (/[0-9]{3}/g.test(zip_code)) {
            getZipCode(zip_code);
          }
        }}
        style={{marginRight: 10, marginBottom: 10, width: 80, flexBasis: 80}}
      />

      <Select
        placeholder="縣市"
        value={city || ''}
        onChange={(value) => {
          onChange({
            city: value,
            district: null,
            zip_code: null,
          });
        }}
        disabled={loading}
        style={{marginRight: 10, marginBottom: 10, width: 100, flexBasis: 100}}>
        <Select.Option value="" key="empty" disabled>
          縣市
        </Select.Option>
        {cities.map((c) => (
          <Select.Option key={c.countyName} value={c.countyName}>
            {c.countyName}
          </Select.Option>
        ))}
      </Select>

      <Select
        placeholder="鄉鎮市區"
        value={district || ''}
        onChange={(value) => {
          onChange({district: value});

          // set zip_code
          let instance = districts.find((t) => t.townName === value);

          if (instance) {
            onChange({
              district: value,
              zip_code: instance.zipCode,
            });
          }
        }}
        disabled={loading}
        style={{marginBottom: 10, width: 100, flexBasis: 100}}>
        <Select.Option value="" key="empty" disabled>
          鄉鎮市區
        </Select.Option>
        {districts.map((t) => (
          <Select.Option key={t.townName} value={t.townName}>
            {t.townName}
          </Select.Option>
        ))}
      </Select>

      <Input
        placeholder="詳細地址路段"
        disabled={loading}
        value={address}
        onChange={(e) => onChange({address: e.target.value})}
        style={{width: '100%', flexBasis: 'auto'}}
      />
    </div>
  );
}
